import React from 'react';
import { useMediaQuery, useTheme, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { IHubspotFormDefinition } from '@lib/components/SwFormBuilder/shared';
import { colors } from '@lib/components/bedrock/fundations/colors';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwDetailsForm from '../app/get-in-touch/SwDetailsForm';
import SwContainer from '../components/v1/SwContainer';
import SwLayout from '../components/v1/SwLayout';
import SwLine from '../components/v1/SwLine';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { grey5, grey6 } from '../lib/components/bedrock/SwColors';
import SwTypography from '../lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '../lib/components/layout/SwWebsiteContainer';

const Page = styled('div')`
    background-color: ${grey6};
`;

const Header = styled('div')`
    padding-block-start: 64px;
    padding-block-end: 64px;
`;

const BookADemoPage = ({ data }) => {
    const { title, subtitle } = data.page;

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav highlightedPage={data.highlightedPage?.nodes?.at(0)} latestUpdates={data.latestArticles.nodes} />
            <SwWebsiteContainer>
                <SwLayout>
                    <Page>
                        <SwContainer withMaxWidth={true}>
                            <Header>
                                <SwTypography color={colors.common.black} component={'h1'} variant={'h3'}>
                                    {title}
                                </SwTypography>
                                <SwTypography color={colors.text.secondary} component={'p'} variant={'h3'}>
                                    {subtitle}
                                </SwTypography>
                            </Header>
                        </SwContainer>
                        <SwLine color={grey5} spacingBottom={4} />
                        <SwContainer noGutter={useMediaQuery(useTheme().breakpoints.down('md'))} withMaxWidth={true}>
                            <SwDetailsForm form={data.form as IHubspotFormDefinition} />
                        </SwContainer>
                        <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                    </Page>
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    data: {
        page: { seo, slug },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={`/${slug}`}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getGetInTouchPageInformation($id: String, $locale: GraphCMS_Locale, $formName: String = "Get In Touch") {
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        form: hubspotForm(name: { eq: $formName }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        enPage: graphCmsPage(slug: { eq: "get-in-touch" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(id: { eq: $id }) {
            id
            remoteId
            title
            subtitle
            slug
            seo {
                ...Essentials_GraphCMS_Seo
            }
        }
    }
`;

export default BookADemoPage;
